body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

section {
  margin-top: 50px;
  margin-bottom: 50px;
}

/* Header section */

.header {
  padding: 5px 20px;
  background-color: #fff;
  box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.1);
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.header .header-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: #00aaff;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-link {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #00aaff;
}

.free-quote-button {
  background-color: #ff6347;
  color: #fff;
  padding: 10px 20px;
  margin: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.free-quote-button:hover {
  background-color: #ff4500;
}

.mobile-menu-icon {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 37px;
    right: 0;
    background-color: #fff;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-block: 30px;
  }

  .nav-link {
    padding: 10px 0;
  }

  .mobile-menu-icon {
    display: block;
    color: #333;
  }
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px 50px 20px;
  background-color: #f8f8f8;
  /* margin-top: 30px; */
}

.hero-content {
  max-width: 55%;
}

.hero h1 {
  font-size: 2.5rem;
  color: #333;
}

.hero p {
  font-size: 1rem;
  color: #666;
  margin: 20px 0;
}

.get-started-button {
  background-color: #00aaff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-started-button:hover {
  background-color: #0077cc;
}

.hero-image {
  position: relative;
  width: 40%;
  display: flex;
  justify-content: center;
  margin-block: 20px;
}

.hero-image img {
  max-width: 70%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
}

.hero-image-text {
  display: flex;
  position: absolute;
  top: 40px;
  left: -40px;
  background: rgba(224, 224, 224, 0.5);
  padding: 10px;
  border-radius: 5px;
  max-width: 60%;
}

.hero-image-text .icon {
  color: #ff6347;
  width: 40px;
}

.hero-image-text p {
  font-size: 0.9rem;
  color: #333;
  margin: 0;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
    padding-top: 30px;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero-image {
    width: 100%;
    margin-block: 60px;
  }

  .hero-image img {
    max-width: 100%;
  }
  .hero-image-text {
    left: -20px;
  }
}

/* Services Section */
.services {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
}

.services h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.service-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.service-card {
  background-color: #f8f8f8;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}

.service-card h3 {
  font-size: 1.3rem;
  color: #333;
}

.service-card .icon {
  font-size: 5rem;
  color: #888;
}

.service-card p {
  font-size: 0.9rem;
  color: #666;
  margin: 10px 0;
}
.service-card ul {
  font-size: 0.9rem;
}
.service-card ul li {
  text-align: left;
  list-style: disc;
  margin-block: 10px;
  margin-left: 10px;
}
.service-card bold {
  font-weight: 700;
}

/* Who We Are Section */
.who-we-are {
  padding: 100px 20px;
  background-color: #f8f8f8;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.who-we-are-content {
  max-width: 45%;
}

.who-we-are h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.who-we-are p {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 20px;
}
.who-we-are bold {
  font-weight: 700;
}

.who-we-are-images {
  display: flex;
  gap: 20px;
}

.who-we-are-image {
  max-width: 100%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .who-we-are {
    flex-direction: column;
    text-align: center;
  }

  .who-we-are-content {
    max-width: 100%;
  }

  .who-we-are-images {
    flex-direction: column;
  }

  .who-we-are-image {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

/* FAQ Section */
.faq {
  padding: 50px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column-reverse;
}

.faq-content {
  width: 100%;
  text-align: center;
}

.faq h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: left;
}

.faq-questions {
  text-align: left;
}

.faq-item {
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.faq-question {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  display: flex;
  justify-content: space-between;
}
.faq-question .faq-icon {
  color: #333;
  margin-right: 10px;
}

.faq-answer {
  margin-top: 10px;
  font-size: 1rem;
  color: #666;
  text-align: left;
}

.faq-img img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

@media (min-width: 769px) {
  .faq {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }

  .faq-content {
    width: 100%;
  }

  .faq-img {
    width: 50%;
  }
}

/* Testimonials section */
.testimonials {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
  background-color: var(--section-background);
}
.testimonials .main-title{
  text-align: center;
  color: #333;
  font-size: 2rem;
  margin-block: 40px;
}
.testimonials .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px;
}
.testimonials .box {
  background-color: white;
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  border-radius: 6px;
  padding: 20px;
  position: relative;
}
.testimonials .box .image {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 10px solid var(--section-background);
  top: -30px;
  right: 0px;
}
.testimonials .box .image img {
  max-width: 100%;
}
.testimonials .box h3 {
  margin: 0 0 10px;
  color: var(--main-color);
}
.testimonials .box .title {
  color: #777;
  margin-bottom: 10px;
  display: block;
}
.testimonials .box .rate .filled {
  color: #ffc107;
}
.testimonials .box p {
  color: #777;
  line-height: 1.5;
  margin: 10px 0 0;
}

/* Contact Us Section */
.contact-us {
  padding: 100px 20px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
}

.contact-us .right, .contact-us .left {
  width: 45%;
}

.contact-us .left {
}

.contact-us .left .block {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.contact-us .left .icon {
  width: 32px;
  height: 32px;
}

.contact-us .left .mail {
  font-weight: 700;
}
.contact-us .left .address {
  font-weight: 700;
  width: 90%;
}

.contact-us h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #00aaff;
  outline: none;
}

.submit-button {
  background-color: #00aaff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0077cc;
}

@media (max-width: 769px) {
  .contact-us {
    flex-direction: column;
  }
  .contact-us .right, .contact-us .left {
    width: 100%;
    margin: 40px 0;
  }
  .contact-us h2 {
    font-size: 1.75rem;
  }
}

/* Footer Section */
.footer {
  background-color: #333;
  color: #fff;
  padding: 50px 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-section {
  max-width: 300px;
}

.footer-section h3 {
  margin-bottom: 15px;
  font-size: 1.4rem;
}

.footer-section p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a * {
  color: #fff;
  font-size: 25px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #00aaff;
}

.footer-bottom {
  margin-top: 50px;
  font-size: 0.9rem;
}

@media (min-width: 769px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }

  .footer-section {
    text-align: left;
  }
  .footer-section h3 {
    text-align: lef;
  }

  .footer-bottom {
    text-align: center;
    width: 100%;
  }
}
